"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = {
    backgroundColor: 'white',
    headerTextColor: 'black',
    labelColor: 'black',
    borderColor: '#e6e6e6',
    previewBorderColor: '#b3b3b3',
    buttonTextColor: '#999999',
    buttonActiveTextColor: '#444444',
};
