"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("./navigation/constants");
var PREVIEW_SCALE = 0.3;
var panelWidth = function (width) { return width * (1 - PREVIEW_SCALE - 0.05); };
exports.getNavigatorPanelPosition = function (animatedValue, previewWidth) {
    return [
        {
            transform: [
                {
                    translateX: animatedValue.interpolate({
                        inputRange: [constants_1.NAVIGATOR, constants_1.PREVIEW],
                        outputRange: [0, -panelWidth(previewWidth) - 1],
                    }),
                },
            ],
            width: panelWidth(previewWidth),
        },
    ];
};
exports.getAddonPanelPosition = function (animatedValue, previewWidth) {
    return [
        {
            transform: [
                {
                    translateX: animatedValue.interpolate({
                        inputRange: [constants_1.PREVIEW, constants_1.ADDONS],
                        outputRange: [previewWidth, previewWidth - panelWidth(previewWidth)],
                    }),
                },
            ],
            width: panelWidth(previewWidth),
        },
    ];
};
exports.getPreviewPosition = function (animatedValue, previewWidth, previewHeight, slideBetweenAnimation) {
    var translateX = previewWidth / 2 - (previewWidth * PREVIEW_SCALE) / 2 - 6;
    var translateY = -(previewHeight / 2 - (previewHeight * PREVIEW_SCALE) / 2 - 12);
    return {
        transform: [
            {
                translateX: animatedValue.interpolate({
                    inputRange: [constants_1.NAVIGATOR, constants_1.PREVIEW, constants_1.ADDONS],
                    outputRange: [translateX, 0, -translateX],
                }),
            },
            {
                translateY: animatedValue.interpolate({
                    inputRange: [constants_1.NAVIGATOR, constants_1.PREVIEW, constants_1.ADDONS],
                    outputRange: [translateY, slideBetweenAnimation ? translateY : 0, translateY],
                }),
            },
        ],
    };
};
exports.getPreviewScale = function (animatedValue, slideBetweenAnimation) {
    return {
        transform: [
            {
                scale: animatedValue.interpolate({
                    inputRange: [constants_1.NAVIGATOR, constants_1.PREVIEW, constants_1.ADDONS],
                    outputRange: [PREVIEW_SCALE, slideBetweenAnimation ? PREVIEW_SCALE : 1, PREVIEW_SCALE],
                }),
            },
        ],
    };
};
