"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
// Android changes screen size when keyboard opens.
// To avoid issues we use absolute positioned element with predefined screen size
var AbsolutePositionedKeyboardAwareView = /** @class */ (function (_super) {
    __extends(AbsolutePositionedKeyboardAwareView, _super);
    function AbsolutePositionedKeyboardAwareView(props) {
        var _this = _super.call(this, props) || this;
        _this.keyboardDidShowHandler = function (e) {
            if (react_native_1.Platform.OS === 'android') {
                var previewWidth = _this.props.previewWidth;
                // There is bug in RN android that keyboardDidShow event is called when you go from portrait to landscape.
                // To make sure that this is keyboard event we check screen width
                if (previewWidth === e.endCoordinates.width) {
                    _this.keyboardOpen = true;
                }
            }
        };
        // When rotating screen from portrait to landscape with keyboard open on android it calls keyboardDidShow, but doesn't call
        // keyboardDidHide. To avoid issues we set keyboardOpen to false immediately on keyboardChange.
        _this.removeKeyboardOnOrientationChange = function () {
            if (react_native_1.Platform.OS === 'android') {
                _this.keyboardOpen = false;
            }
        };
        _this.keyboardDidHideHandler = function () {
            if (_this.keyboardOpen) {
                _this.keyboardOpen = false;
            }
        };
        _this.onLayoutHandler = function (_a) {
            var nativeEvent = _a.nativeEvent;
            if (!_this.keyboardOpen) {
                var _b = nativeEvent.layout, width = _b.width, height = _b.height;
                var onLayout = _this.props.onLayout;
                onLayout({
                    previewHeight: height,
                    previewWidth: width,
                });
            }
        };
        _this.keyboardDidShowListener = react_native_1.Keyboard.addListener('keyboardDidShow', _this.keyboardDidShowHandler);
        _this.keyboardDidHideListener = react_native_1.Keyboard.addListener('keyboardDidHide', _this.keyboardDidHideHandler);
        react_native_1.Dimensions.addEventListener('change', _this.removeKeyboardOnOrientationChange);
        return _this;
    }
    AbsolutePositionedKeyboardAwareView.prototype.componentWillUnmount = function () {
        this.keyboardDidShowListener.remove();
        this.keyboardDidHideListener.remove();
        react_native_1.Dimensions.removeEventListener('change', this.removeKeyboardOnOrientationChange);
    };
    AbsolutePositionedKeyboardAwareView.prototype.render = function () {
        var _a = this.props, children = _a.children, previewWidth = _a.previewWidth, previewHeight = _a.previewHeight;
        return (react_1.default.createElement(react_native_1.View, { style: { flex: 1 }, onLayout: this.onLayoutHandler },
            react_1.default.createElement(react_native_1.View, { style: previewWidth === 0
                    ? { flex: 1 }
                    : { position: 'absolute', width: previewWidth, height: previewHeight } }, children)));
    };
    return AbsolutePositionedKeyboardAwareView;
}(react_1.PureComponent));
exports.default = AbsolutePositionedKeyboardAwareView;
