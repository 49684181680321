"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var style = react_native_1.StyleSheet.create({
    invisible: {
        height: 0,
        width: 0,
        opacity: 0,
        position: 'absolute',
    },
    flex: {
        flex: 1,
    },
});
var Wrapper = /** @class */ (function (_super) {
    __extends(Wrapper, _super);
    function Wrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Wrapper.prototype.render = function () {
        var _a = this.props, panels = _a.panels, addonSelected = _a.addonSelected;
        var addonKeys = Object.keys(panels);
        return addonKeys.map(function (id) {
            var selected = addonSelected === id;
            return (react_1.default.createElement(react_native_1.View, { key: id, style: selected ? style.flex : style.invisible },
                react_1.default.createElement(react_native_1.ScrollView, null, panels[id].render({ active: selected, key: id }))));
        });
    };
    Wrapper.defaultProps = {
        addonSelected: '',
    };
    return Wrapper;
}(react_1.PureComponent));
exports.default = Wrapper;
