import transform from 'css-to-react-native';
import { forwardRef, createElement } from 'react';
import { ThemeContext } from '@emotion/core';

var testAlwaysTrue = function testAlwaysTrue() {
  return true;
};
var pickAssign = function pickAssign(testFn, target) {
  var i = 2;
  var length = arguments.length;

  for (; i < length; i++) {
    var source = arguments[i];

    var _key = void 0;

    for (_key in source) {
      if (testFn(_key)) {
        target[_key] = source[_key];
      }
    }
  }

  return target;
};
function interleave(vals) {
  var strings = vals[0];
  var finalArray = [strings[0]];

  for (var i = 1, len = vals.length; i < len; i++) {
    finalArray.push(vals[i]);

    if (strings[i] !== undefined) {
      finalArray.push(strings[i]);
    }
  }

  return finalArray;
}

// they're reset on every call to css
// this is done so we don't create a new
// handleInterpolation function on every css call

var styles;
var buffer;
var lastType;

function handleInterpolation(interpolation, i, arr) {
  var type = typeof interpolation;

  if (type === 'string') {
    // strip comments
    interpolation = interpolation.replace(/\/\*[\s\S]*?\*\/|\/\/.*$/gm, '');
  }

  if (type === 'function') {
    if (this === undefined) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Interpolating functions in css calls is not allowed.\n' + 'If you want to have a css call based on props, create a function that returns a css call like this\n' + 'let dynamicStyle = (props) => css`color: ${props.color}`\n' + 'It can be called directly with props or interpolated in a styled call like this\n' + 'let SomeComponent = styled.View`${dynamicStyle}`');
      }
    } else {
      handleInterpolation.call(this, interpolation( // $FlowFixMe
      this), i, arr);
    }

    return;
  }

  var isIrrelevant = interpolation == null || type === 'boolean';
  var isRnStyle = type === 'object' && !Array.isArray(interpolation) || type === 'number';

  if (lastType === 'string' && (isRnStyle || isIrrelevant)) {
    var converted = convertStyles(buffer);

    if (converted !== undefined) {
      styles.push(converted);
    }

    buffer = '';
  }

  if (isIrrelevant) {
    return;
  }

  if (type === 'string') {
    buffer += interpolation;

    if (arr.length - 1 === i) {
      var _converted = convertStyles(buffer);

      if (_converted !== undefined) {
        styles.push(_converted);
      }

      buffer = '';
    }
  }

  if (isRnStyle) {
    styles.push(interpolation);
  }

  if (Array.isArray(interpolation)) {
    interpolation.forEach(handleInterpolation, this);
  }

  lastType = type;
} // Use platform specific StyleSheet method for creating the styles.
// This enables us to use the css``/css({}) in any environment (Native | Sketch | Web)


function createCss(StyleSheet) {
  return function css() {
    var vals; // these are declared earlier
    // this is done so we don't create a new
    // handleInterpolation function on every css call

    styles = [];
    buffer = '';
    lastType = undefined;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (args[0] == null || args[0].raw === undefined) {
      vals = args;
    } else {
      vals = interleave(args);
    }

    vals.forEach(handleInterpolation, this);
    return StyleSheet.flatten(styles);
  };
}
var propertyValuePattern = /\s*([^\s]+)\s*:\s*(.+?)\s*$/;

function convertPropertyValue(style) {
  // Get prop name and prop value
  var match = propertyValuePattern.exec(style); // match[2] will be " " in cases where there is no value
  // but there is whitespace, e.g. "color: "

  if (match !== null && match[2] !== ' ') {
    // the first value in the array will
    // be the whole string so we remove it
    match.shift(); // yes i know this looks funny

    this.push(match);
  }
}

function convertStyles(str) {
  if (str.trim() === '') return;
  var stylePairs = [];
  var parsedString = str.split(';');
  parsedString.forEach(convertPropertyValue, stylePairs);

  try {
    return transform(stylePairs);
  } catch (error) {
    var msg = error.message;

    if (msg.includes('Failed to parse declaration')) {
      var values = msg.replace('Failed to parse declaration ', '').replace(/"/g, '').trim().split(':');
      var errorMsg = "'" + values[0] + "' shorthand property requires units for example - " + values[0] + ": 20px or " + values[0] + ": 10px 20px 40px 50px";
      console.error(errorMsg);
    }
  }
}

var defaultPickTest = function defaultPickTest(prop) {
  return prop !== 'theme' && prop !== 'innerRef';
};

function createStyled(StyleSheet, _temp) {
  var _ref = _temp === void 0 ? {} : _temp,
      _ref$getShouldForward = _ref.getShouldForwardProp,
      getShouldForwardProp = _ref$getShouldForward === void 0 ? function () {
    return defaultPickTest;
  } : _ref$getShouldForward;

  var css = createCss(StyleSheet);
  return function createEmotion(component) {
    var pickTest = getShouldForwardProp(component);
    return function createStyledComponent() {
      var styles;

      for (var _len = arguments.length, rawStyles = new Array(_len), _key = 0; _key < _len; _key++) {
        rawStyles[_key] = arguments[_key];
      }

      if (rawStyles[0] == null || rawStyles[0].raw === undefined) {
        styles = rawStyles;
      } else {
        styles = interleave(rawStyles);
      } // do we really want to use the same infra as the web since it only really uses theming?
      // $FlowFixMe


      var Styled = forwardRef(function (props, ref) {
        return createElement(ThemeContext.Consumer, null, function (theme) {
          var mergedProps = pickAssign(testAlwaysTrue, {}, props, {
            theme: props.theme || theme
          });
          var stylesWithStyleProp = styles;

          if (props.style) {
            stylesWithStyleProp = styles.concat(props.style);
          }

          var emotionStyles = css.apply(mergedProps, stylesWithStyleProp);

          if (process.env.NODE_ENV !== 'production' && props.innerRef) {
            console.error('innerRef is no longer supported, please use ref instead');
          }

          return createElement(component, pickAssign(pickTest, {}, props, {
            ref: ref,
            style: emotionStyles
          }));
        });
      }); // $FlowFixMe

      Styled.withComponent = function (newComponent) {
        return createEmotion(newComponent).apply(void 0, styles);
      };

      Styled.displayName = "emotion(" + getDisplayName(component) + ")";
      return Styled;
    };
  };
}

var getDisplayName = function getDisplayName(primitive) {
  return typeof primitive === 'string' ? primitive : primitive.displayName || primitive.name || 'Styled';
};

export { createCss, createStyled };
